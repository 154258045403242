import React, { useState, useEffect } from "react";
import { Slider } from "@mui/material/";

const MySlider = () => {
  const [baseRate, setBaseRate] = useState(6.63);
  const [value, setValue] = useState(baseRate); // 初始值设置为 10

  useEffect(() => {
    // 定义一个获取基础利率的函数
    const fetchBaseRate = async () => {
      try {
        // 替换为您的后端地址和端点
        const response = await fetch("https://api.dti-cal.com/get-latest-rate");
        const data = await response.json();
        setBaseRate(data.latest_rate); // 假设后端返回的数据结构是 { rate: 6.63 }
      } catch (error) {
        console.error("Failed to fetch base rate:", error);
      }
    };
    // 首次加载时获取基础利率
    fetchBaseRate();

    // 设置定时器，每两小时更新一次
    const intervalId = setInterval(fetchBaseRate, 12 * 60 * 60 * 1000); // 12 hours in milliseconds

    // 清除定时器
    return () => clearInterval(intervalId);
  }, []); // 空数组保证这个效果只在首次渲染时运行

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const valuemin = baseRate - 1.25;
  const valuemax = baseRate + 1.25;

  return (
    <div className="SliderContainer self-stretch h-[121px] p-6 bg-white rounded-xl border border-zinc-200 flex-col justify-center items-start gap-6 flex">
      <div className="Frame353 self-stretch h-[73px] flex-col justify-center items-center gap-1 flex">
        <div className="Pages self-stretch text-slate-700 text-l font-semibold font-['Inter'] leading-relaxed">
          Expected APR
        </div>
        <div className="Frame203 self-stretch justify-between items-center inline-flex">
          <Slider
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
            min={valuemin}
            max={valuemax}
            valueLabelDisplay="auto"
            step={0.125}
            color="secondary"
          />
          <div className="ValueDisplay">{value}%</div>
        </div>
      </div>
    </div>
  );
};

export default MySlider;
