import React, { useState, useEffect } from "react";
import ResultFloating from "./ResultFloating";
import { useFinancial } from "./FinancialProvider";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import StyledButton from "./StyledButton";
import NestedModal from "./NestedModal";

const DisplayComponent = () => {
  // 解构所需数据
  const {
    computedTotalIncome,
    computedTotalDebt,
    computedTotalHousingExpense,
    frontDTI,
    backDTI,
  } = useFinancial();
  //控制整个panal的滚动

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // 模拟登录状态切换（实际应用中应该是根据用户的登录状态来设置）
  const toggleLogin = () => setIsLoggedIn(!isLoggedIn);

  const [scrolling, setScrolling] = useState(false);
  let scrollTimer = null;

  const handleScroll = () => {
    setScrolling(true);
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(() => {
      setScrolling(false);
    }, 150); // 150ms 后无滚动则认为停止
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100, // 滚动多少像素后触发
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  // 格式化数据

  const formattedFrontDTI = frontDTI ? `${frontDTI.toFixed(2)}%` : "0%";
  const formattedBackDTI = backDTI ? `${backDTI.toFixed(2)}%` : "0%";

  return (
    <div
      className={`Results  ${
        trigger ? "fixed-class" : "relative-class"
      } w-[390px] h-[775px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(50.33,47.03,88.19)_0%,rgba(24.47,21.25,42.5,0)_100%)] rounded-[16px] border border-solid border-primary-slate-700 justify-center items-start flex`}
    >
      <div className="display-component grow shrink basis-0  flex-col justify-start items-start inline-flex">
        <div className="Result self-stretch  px-6 py-8 flex-col justify-start items-center gap-[18px] flex">
          <div className="ResultTitle w-[185px] pb-14 relative">
            <div className="Pages left-[57px] top-0 absolute text-white text-2xl font-semibold font-['Inter']">
              Result
            </div>
            <div className="Pages w-[220p··x] left-[-12.50px] top-[41px] absolute text-center text-white text-sm font-semibold font-['Inter'] leading-relaxed">
              Front and Back end DTI Ratio
            </div>
          </div>
          <ResultFloating
            label="Total Income"
            datainput={computedTotalIncome}
          />

          <ResultFloating label="Total Debt" datainput={computedTotalDebt} />
          <ResultFloating
            label="Total Housing Expense"
            datainput={computedTotalHousingExpense}
          />
          <div className="Frame256 w-[342px] h-1.5 opacity-30 justify-center items-center gap-2.5 inline-flex">
            <div className="Line2 grow shrink basis-0 h-0 self-stretch border border-solid border-gray-400"></div>
          </div>
          <ResultFloating label="Front DTI" datainput={formattedFrontDTI} />
          <ResultFloating label="Back DTI" datainput={formattedBackDTI} />
          <div className="Frame256 w-[342px] h-1.5 opacity-30 justify-center items-center gap-2.5 inline-flex">
            <div className="Line2 grow shrink basis-0 h-0 self-stretch border border-solid border-gray-400"></div>
          </div>
          <div className="ButtonZone self-stretch h-[50px] p-1  rounded-lg  justify-center items-center gap-10  inline-flex">
            <NestedModal
              showModal={showModal}
              setShowModal={setShowModal}
              toggleLogin={toggleLogin}
            />
            <StyledButton label="Reset" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayComponent;
