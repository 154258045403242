import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";
import Title from "./Title";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

const data = [
  createData("2023/12/1", 7),
  createData("2023/12/2", 7.125),
  createData("2023/12/3", 7.5),
  createData("2023/12/4", 7.25),
  createData("2023/12/5", 7.875),
  createData("2023/12/6", 7.5),
  createData("2023/12/7", 7.625),
  createData("2023/12/8", 7.75),
];

export default function Chart() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title sx={{ color: '#2A224E' }}>This Week's Rate</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="time"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[6.5, "auto"]}
            ticks={[6.5, 7, 7.5, 8]}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Rate (%)
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="amount"
            stroke="#9C27B0"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
