import React from "react";

const ResultFloating = ({ label, datainput }) => {
  return (
    <div className="floating-panel self-stretch h-[90px] p-6 bg-gray-800 bg-opacity-40 rounded-lg flex-col justify-start items-center gap-3 flex">
      <div className="Pages self-stretch text-center text-gray-400 text-sm font-semibold font-['Inter'] leading-relaxed">
        {label}
      </div>
      <div className="Pages self-stretch text-center text-white text-xl font-semibold font-['Inter']">
        {datainput}
      </div>
      {/* Content goes here */}
    </div>
  );
};

export default ResultFloating;
