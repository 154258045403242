import React from "react";
import IncomeForm from "./forms/IncomeForm";
import DebtForm from "./forms/DebtForm";
import REOForm from "./forms/REOForm";
import SubjectPropertyForm from "./forms/SubjectPorpertyForm";
import Navigation from "./Navigation";
import MySlider from "./MySlider";
import HeroText from "./HeroText";
import { useFinancial } from "./FinancialProvider";

// 假设您有一个 DisplayComponent 用于显示计算结果
import DisplayComponent from "./DisplayComponent";

const CalculatorDesktop = () => {
  const { reoProperties, addREOProperty, removeREOProperty } = useFinancial();

  return (
    <div className="CalculatorDesktop w-full min-h-full  [background:radial-gradient(50%_50%_at_50%_50%,rgb(31,30,51)_0%,rgb(42.02,34.4,78.63)_87.18%)] justify-center items-center  flex flex-col">
      <Navigation />
      <HeroText />
      <div className="Content w-full relative p-[50px] gap-20 flex flex-nowrap justify-center">
        <div className="Forms  bg-white rounded-2xl shadow border border-solid border-zinc-200 flex-col justify-start items-start inline-flex p-6">
          <div className="Formsframe self-stretch  px-6 py-8 flex-col justify-center items-center gap-6 flex">
            <div className="Header flex-col justify-start items-center gap-3 flex">
              <div className="Pages text-slate-700 text-2xl font-semibold font-['Inter']">
                Debt to Income calculator{" "}
              </div>
              <div className="Pages w-[727px] text-center text-slate-700 text-sm font-normal font-['Inter'] leading-relaxed">
                *needs to be filled
              </div>
            </div>
            <IncomeForm />

            <DebtForm />
            <SubjectPropertyForm />

            {reoProperties.map((_, index) => (
              <REOForm
                key={index}
                index={index}
                onAdd={addREOProperty}
                onRemove={() => removeREOProperty(index)}
              />
            ))}

            <MySlider />
          </div>
        </div>

        <DisplayComponent />
      </div>
    </div>
  );
};

export default CalculatorDesktop;
