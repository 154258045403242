import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits() {
  return (
    <React.Fragment>
      <Title>Today's Rate</Title>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Typography component="p" variant="h4">
          7.75%
        </Typography>
        <Typography color="text.secondary">
          on December 8, 2023
        </Typography>
      </div>
      <div>
        <Link color="#800080" href="#" onClick={preventDefault}>
          View rates
        </Link>
      </div>
    </React.Fragment>
  );
}