// SubjectPropertyForm.js
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import NumberInput from "../NumberInput";
import TextInput from "../TextInput";
import { useFinancial } from "../FinancialProvider";
import * as Yup from "yup";

// 定义验证模式
const validationSchema = Yup.object({
  SPMortgagePI: Yup.number()
    .required("Mortgage PI is required")
    .positive("Mortgage PI must be positive"),
});

const SubjectPropertyForm = () => {
  const {
    SPMortgagePI,
    setSPMortgagePI,
    SPPropertyTax,
    setSPPropertyTax,
    SPHomeownerInsurance,
    setSPHomeownerInsurance,
    SPHoa,
    setSPHoa,
    SPSolar,
    setSPSolar,
    SPTotalHousingExpense,
    setSPTotalHousingExpense,
  } = useFinancial();

  const updateSPHousingExpense = () => {
    const total =
      SPMortgagePI + SPPropertyTax + SPHomeownerInsurance + SPHoa + SPSolar;

    setSPTotalHousingExpense(total); // 更新 totalHousingExpense
  };

  useEffect(() => {
    updateSPHousingExpense();
  });

  return (
    <Formik
      initialValues={{
        propertyAddress: "",
        SPpropertyPI: "",
        SPpropertyTax: "",
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="Reoforms w-full  p-6 bg-white rounded-xl border border-solid border-zinc-200 flex-col justify-start items-start gap-6 flex">
          <div className="Title text-slate-700 text-lg font-medium font-['Inter']">
            Subject Property
          </div>
          <div className="ReoFrame1 self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <TextInput
              label="Property Address"
              fieldname="propertyAddress"
              placeholder="Property Address"
              width={310}
            />
            <NumberInput
              label="mortgage P&I"
              fieldname="mortgagePI"
              placeholder="mortgage P&I"
              width={160}
              value={SPMortgagePI}
              onChange={(value) => {
                setSPMortgagePI(parseFloat(value) || 0); // 更新 mortgagePI
              }}
            />
            <NumberInput
              label="property Tax"
              fieldname="propertyTax"
              placeholder="Property Tax"
              width={160}
              value={SPPropertyTax}
              onChange={(value) => {
                setSPPropertyTax(parseFloat(value) || 0); // 更新 propertyTax
              }}
            />
          </div>
          <div className="ReoFrame2 self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <NumberInput
              label="homeowner insurance"
              fieldname="homeownerInsurance"
              placeholder="homeowner insurance"
              width={310}
              value={SPHomeownerInsurance}
              onChange={(value) => {
                setSPHomeownerInsurance(parseFloat(value) || 0); // 更新 homeownerInsurance
              }}
            />
            <NumberInput
              label="hoa"
              fieldname="hoa"
              placeholder="hoa"
              width={160}
              value={SPHoa}
              onChange={(value) => {
                setSPHoa(parseFloat(value) || 0); // 更新 hoa
              }}
            />
            <NumberInput
              label="solar"
              fieldname="solar"
              placeholder="solar"
              width={160}
              value={SPSolar}
              onChange={(value) => {
                setSPSolar(parseFloat(value) || 0); // 更新 solar
              }}
            />
          </div>
          <div className="ReoFrame3 self-stretch h-[69px] justify-end items-start gap-[25px] inline-flex ">
            <div className="HousingExpense w-[342px] h-[68px] p-6 bg-gray-800 bg-opacity-80 rounded-lg justify-center items-center gap-3 flex">
              <div className="Title grow shrink basis-0 text-center text-white text-sm font-semibold font-['Inter'] leading-relaxed">
                Housing Expense
              </div>
              <div className="Figure grow shrink basis-0 text-center text-white text-[28px] font-bold font-['Inter']">
                ${SPTotalHousingExpense.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SubjectPropertyForm;
