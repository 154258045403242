import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Button, CircularProgress } from "@mui/material";
import StyledButton from "./StyledButton";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useFinancial } from "./FinancialProvider";
import { useSubmitForm } from "./SubmitFormContext"; // 确保从正确的路径导入

export default function NestedModal() {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false); // New state to manage saving state
  const [saveSuccess, setSaveSuccess] = React.useState(false); // New state to manage save success state
  const { isLoggedIn } = useAuth();
  const { submitForms, validationFailed, setValidationFailed } =
    useSubmitForm(); // 使用context中的submitForms

  const handleClose = () => {
    setOpen(false);
    setSaveSuccess(false); // Reset the saving state
    setSaving(false); // Reset the save success state
    setValidationFailed(false); // 重置验证失败状态
  };

  const { exportFinancialDataAsJson } = useFinancial(); // 使用解构来获取函数

  const handleSaveResults = async () => {
    if (!isLoggedIn) {
      setOpen(true); // 如果未登录，打开模态框提示登录
      return;
    }
    setSaving(true); // 开始保存操作之前，设置保存状态为true
    try {
      await submitForms(); // 提交所有注册的表单
      setSaveSuccess(true); // 如果提交成功，更新保存成功状态
    } catch (error) {
      console.error("Error submitting forms:", error);
      setValidationFailed(true);
      // 处理提交表单时的错误，例如显示错误消息
    } finally {
      setSaving(false); // 无论成功或失败，最后都要关闭保存状态
    }
  };

  return (
    <div>
      <StyledButton label="Save" onClick={handleSaveResults} />
      <Modal
        open={open || saving || saveSuccess || validationFailed}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={style}>
          {saving && (
            <React.Fragment>
              <CircularProgress />
              <p id="saving-progress-description" className="modal-description">
                Saving your results...
              </p>
            </React.Fragment>
          )}
          {saveSuccess && (
            <React.Fragment>
              <h2 id="parent-modal-title" className="modal-title">
                Save Your Results
              </h2>
              <p id="parent-modal-description" className="modal-description">
                Your results will be saved.
              </p>
            </React.Fragment>
          )}
          {validationFailed && (
            <React.Fragment>
              <h2 id="parent-modal-title" className="modal-title">
                Validation Error
              </h2>
              <p id="parent-modal-description" className="modal-description">
                Please fill up the required blanks before saving.
              </p>
            </React.Fragment>
          )}
          {!isLoggedIn && !saving && !saveSuccess && (
            <React.Fragment>
              <h2 id="parent-modal-title" className="modal-title">
                Hey there! 👋
              </h2>
              <p id="parent-modal-description" className="modal-description">
                Please Sign in to save your results.
              </p>
              <div className="flex justify-center items-center gap-4 mt-4">
                <Link to="/signup">
                  <StyledButton
                    onClick={handleClose}
                    variant="line"
                    label="Sign Up"
                  ></StyledButton>
                </Link>
                <Link to="/signin">
                  <StyledButton
                    onClick={handleClose}
                    label="Sign In"
                  ></StyledButton>
                </Link>
              </div>
            </React.Fragment>
          )}
          <Button variant="text" onClick={handleClose} className="modal-button">
            Close
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: Inter;
    font-weight: 500;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);
