import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NumberInput from "../NumberInput";
import { useFinancial } from "../FinancialProvider";

// 创建一个自定义组件来处理债务总额的计算

const DebtForm = () => {
  const { debts, addDebt, removeDebt, updateDebt } = useFinancial();
  const [error, setError] = useState(""); // 新增错误状态

  const handleAddClick = () => {
    // 检查最后一个债务是否已正确填写
    const lastDebt = debts[debts.length - 1];
    if (!lastDebt.debtType || lastDebt.amount <= 0) {
      // 如果未选择债务类型或金额为0，则显示错误并阻止添加
      setError(
        "You have to choose a debt type and specify an amount greater than 0."
      );
      return;
    }

    // 添加新的债务条目
    addDebt({ debtType: "", amount: 0 });
    setError(""); // 清除错误消息
  };

  return (
    <div className="Debtforms w-full p-6 bg-white rounded-xl border border-solid border-zinc-200 flex-col justify-start items-start gap-6 flex">
      <div className="Title text-slate-700 text-lg font-medium font-['Inter']">
        Debts
      </div>
      {debts.map((debt, index) => (
        <div
          key={index}
          className="Debt self-stretch justify-start items-center gap-5 inline-flex mb-4 mt-4"
        >
          <select
            name={`debtType`}
            value={debt.debtType}
            onChange={(e) =>
              updateDebt(index, { ...debt, debtType: e.target.value })
            }
            className="p-3 rounded-lg border text-sm border-gray-300 w-full max-w-[200px]"
          >
            <option value="">Select a Debt Type</option>
            <option value="creditCard">Credit Card</option>
            <option value="studentLoan">Student Loan</option>
            <option value="rentPayment">Rent Payment</option>
            <option value="mortgagePayment">Mortgage Payment</option>
            <option value="otherDebt">Other Monthly Payment</option>
          </select>
          <NumberInput
            label="Monthly Debt"
            value={debt.amount}
            onChange={(newValue) =>
              updateDebt(index, { ...debt, amount: newValue })
            }
            placeholder="Monthly Debt"
            width={310}
          />
          <IconButton
            onClick={() => removeDebt(index)}
            aria-label="delete"
            disabled={debts.length === 1}
          >
            <DeleteIcon />
          </IconButton>
          {index === debts.length - 1 && (
            <IconButton onClick={handleAddClick} aria-label="add">
              <AddIcon />
            </IconButton>
          )}
        </div>
      ))}
      {error && <div style={{ color: "red" }}>{error}</div>}{" "}
    </div>
  );
};

export default DebtForm;
