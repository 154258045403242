import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CalculatorDesktop from "./components/CalculatorDesktop";
import { AuthProvider } from "./components/AuthContext";
import SignInSide from "./components/SignInSide";
import SignUp from "./components/SignUp";
import Dashboard from "./components/dashboard/Dashboard";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<CalculatorDesktop />} />
            <Route path="/signin" element={<SignInSide />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* 如果有其他页面，也可以在这里添加路由 */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
