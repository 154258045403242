import React from "react";
import { Button } from "@mui/material";

const StyledButton = ({
  label,
  onClick,
  width = "100px",
  variant = "solid",
  solidBg = "var(--gray-800)",
}) => {
  const isLineVariant = variant === "line";

  return (
    <Button
      onClick={onClick}
      sx={{
        width: width,
        height: "45px",
        backgroundColor: isLineVariant ? "transparent" : solidBg, // 如果是线框则透明背景，否则使用 solidBg 颜色
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",
        color: isLineVariant ? solidBg : "white", // 如果是线框则使用 solidBg 颜色，否则为白色
        fontSize: "1rem",
        fontWeight: "bold",
        fontFamily: "Inter",
        border: isLineVariant ? `1px solid ${solidBg}` : "none", // 如果是线框则使用 solidBg 颜色的边框
        "&:hover": {
          backgroundColor: isLineVariant
            ? "rgba(32,28,55, 0.2)"
            : "rgba(128, 128, 128, 0.5)", // 悬停时的背景颜色
        },
      }}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
