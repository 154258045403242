import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useAuth } from "./AuthContext";
import DashboardIcon from "@mui/icons-material/Dashboard";

function Navigation() {
  const { isLoggedIn, user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };
  const userName = user ? `${user.firstname} ${user.lastname}` : "";
  return (
    <nav className="Navigation w-full h-16 relative justify-between pl-10 pr-10 flex items-center">
      <div className="Logotype w-[133.60px] h-[38px] ">
        <div className="Ellipse21 w-[38px] h-[38px]  bg-gradient-to-b from-zinc-900 to-violet-500 rounded-full"></div>
      </div>

      <div className="PageTitle  justify-start items-start gap-[60px] inline-flex">
        <div className="CalculatorSimulator text-white text-xl font-semibold font-['Inter']">
          Calculator simulator
        </div>
      </div>
      <div className="MenuItems  justify-center items-cneter gap-1 inline-flex">
        {isLoggedIn ? (
          <>
            <div className="UserInfo justify-center items-center text-white text-l font-semibold font-['Inter']">
              Welcome {userName}!{/* 显示登录用户的用户名 */}
            </div>
            <Link to="/dashboard">
              <DashboardIcon sx={{ color: "white" }} />
              <Button
                variant="text"
                color="primary"
                sx={{ color: "white", fontSize: "12px" }}
              >
                Dashboard
              </Button>
            </Link>
            <Button
              onClick={handleLogout}
              variant="text"
              color="primary"
              sx={{ color: "white" }}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <div className="Actions w-20 justify-start items-center gap-4 flex">
              <Link
                to="/signin"
                className="Actions w-20 justify-start items-center gap-4 flex"
              >
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "var(--primary-slate-700)",
                      backgroundColor: "white",
                    },
                  }}
                >
                  Sign in
                </Button>
              </Link>
            </div>
            <div className="Button w-20  rounded border border-white justify-center items-center gap-2 flex">
              <Link
                to="/signup"
                className="Button w-20  rounded border border-white justify-center items-center gap-2 flex"
              >
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    border: "1px solid white",
                    color: "white",
                    "&:hover": {
                      color: "var(--primry-slate-700)",
                      backgroundColor: "white",
                    },
                  }}
                >
                  Sign up
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
