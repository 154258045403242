import React, { createContext, useContext, useState, useCallback } from "react";

// 创建Context
const SubmitFormContext = createContext({
  submitForms: () => {},
  registerForm: () => {},
  unregisterForm: () => {},
});

// 创建Provider组件
export const SubmitFormProvider = ({ children }) => {
  const [forms, setForms] = useState({});
  const [validationFailed, setValidationFailed] = useState(false); // 新增状态

  // 注册表单
  const registerForm = useCallback((formName, submitFunction) => {
    setForms((prevForms) => ({
      ...prevForms,
      [formName]: { submitFunction: submitFunction, isValid: true }, // 存储表单提交函数及验证状态
    }));
  }, []);

  // 取消注册表单
  const unregisterForm = useCallback((formName) => {
    setForms((prevForms) => {
      const newForms = { ...prevForms };
      delete newForms[formName];
      return newForms;
    });
  }, []);

  // 提交所有表单
  const submitForms = useCallback(async () => {
    console.log("Starting to submit all forms...");
    let allValid = true;
    for (const [formName, { submitFunction }] of Object.entries(forms)) {
      try {
        console.log(`Submitting form: ${formName}`);
        await submitFunction();
      } catch (error) {
        allValid = false;
        console.error(`Error submitting form: ${formName}`, error);
      }
    }
    if (!allValid) {
      throw new Error("Validation failed for one or more forms.");
    }
  }, [forms]);

  const contextValue = {
    submitForms,
    registerForm,
    unregisterForm,
    validationFailed, // 将验证失败状态暴露给消费者
    setValidationFailed, // 允许直接更新验证状态
  };

  return (
    <SubmitFormContext.Provider value={contextValue}>
      {children}
    </SubmitFormContext.Provider>
  );
};

export const useSubmitForm = () => useContext(SubmitFormContext);
