import React from "react";
import TextField from "@mui/material/TextField";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";

const TextInput = ({
  label,
  fieldname,
  placeholder,
  width,
  value,
  onChange,
  onBlur,
  error = false,
  helperText = "",
}) => {
  const handleInputChange = (e) => {
    onChange(e); // 调用传入的onChange函数，通知父组件值的变化
  };
  // 处理焦点离开
  const handleBlur = (e) => {
    onBlur && onBlur(e);
  };
  return (
    <TextField
      label={label}
      name={fieldname}
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur} // 添加onBlur处理
      placeholder={placeholder}
      variant="outlined"
      error={error}
      helperText={helperText}
      style={{ width: width ? `${width}px` : "auto" }} // 直接设置宽度
      inputProps={{
        s: (
          <InputAdornment position="start">
            <AccountCircleIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-input": {
          height: "16px",
        },
      }}
    />
  );
};

export default TextInput;
