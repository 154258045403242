import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Generate Order Data
function createData(id, date, name, email, phone, frontDTI, backDTI) {
  return { id, date, name, email, phone, frontDTI, backDTI };
}

const rows = [
  createData(0, "16 Mar, 2019", "Elvis Presley", "elvis@example.com", "(555) 123-4567", "18%/28%", "37%/50%"),
  createData(1, "16 Mar, 2019", "Paul McCartney", "paul@example.com", "(987) 654-3210", "20%/28%", "32%/50%"),
  createData(2, "16 Mar, 2019", "Tom Scholz", "tom@example.com", "(123) 456-7890", "32%/28%", "44%/50%"),
  createData(3, "16 Mar, 2019", "Michael Jackson", "michael@example.com", "(567) 890-1234", "27%/28%", "56%/50%"),
  createData(4, "15 Mar, 2019", "Bruce Springsteen", "bruce@example.com", "(321) 678-9012", "12%/28%", "28%/50%"),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Title>Saved Cases</Title>
          <TextField label="Search" variant="outlined" size="small" sx={{ ml: 2 }} />
        </div>
        <Button variant="contained" color="primary" sx={{ bgcolor: "#201C37", "&:hover": { bgcolor: "#535062" } }}>
          Add New
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Front DTI Value</TableCell>
            <TableCell align="left">Back DTI Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.date}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phone}</TableCell>
              <TableCell align="left">
                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                  {parseFloat(row.frontDTI) > 28 ? (
                    <Typography color="error" sx={{ fontSize: "inherit" }}>{row.frontDTI}</Typography>
                  ) : (
                    <Typography color="initial" sx={{ fontSize: "inherit" }}>{row.frontDTI}</Typography>
                  )}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                  {parseFloat(row.backDTI) > 50 ? (
                    <Typography color="error" sx={{ fontSize: "inherit" }}>{row.backDTI}</Typography>
                  ) : (
                    <Typography color="initial" sx={{ fontSize: "inherit" }}>{row.backDTI}</Typography>
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="#800080" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more cases
      </Link>
    </React.Fragment>
  );
}
