// REOForm.js
import React from "react";
import { Formik, Form } from "formik";
import NumberInput from "../NumberInput";
import TextInput from "../TextInput";
import { useFinancial } from "../FinancialProvider";

const REOForm = ({ index, onAdd, onRemove }) => {
  const { reoProperties, setReoProperties } = useFinancial();

  // 确保索引有效
  if (index < 0 || index >= reoProperties.length) {
    console.error("Index is out of range in REOForm.");
    return null; // 如果索引无效，不渲染组件
  }

  // 在使用 REOForm 的地方

  const property = reoProperties[index];

  // 更新特定索引的 REO 属性
  const updateProperty = (field, value) => {
    const updatedProperties = reoProperties.map((prop, idx) =>
      idx === index ? { ...prop, [field]: parseFloat(value) || 0 } : prop
    );
    setReoProperties(updatedProperties);
  };

  return (
    <Formik initialValues={property}>
      <Form>
        <div className="Reoforms w-full  p-6 bg-white rounded-xl border border-solid border-zinc-200 flex-col justify-start items-start gap-6 flex">
          <div className="Title text-slate-700 text-lg font-medium font-['Inter']">
            Real Estate Owned
          </div>
          <button type="button" onClick={() => onAdd(index)}>
            Add Another Property
          </button>
          {reoProperties.length > 1 && (
            <button type="button" onClick={() => onRemove(index)}>
              Remove This Property
            </button>
          )}
          <div className="ReoFrame1 self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <TextInput
              label="Property Address"
              fieldname={`reoProperties[${index}].propertyAddress`}
              placeholder="Property Address"
              width={310}
            />
            <NumberInput
              label="mortgage P&I"
              fieldname="mortgagePI"
              placeholder="mortgage P&I"
              width={160}
              value={property.mortgagePI}
              onChange={(value) => updateProperty("mortgagePI", value)}
            />
            <NumberInput
              label="property Tax"
              fieldname="propertyTax"
              placeholder="Property Tax"
              width={160}
              value={property.propertyTax}
              onChange={(value) => updateProperty("propertyTax", value)}
            />
          </div>
          <div className="ReoFrame2 self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <NumberInput
              label="homeowner insurance"
              fieldname="homeownerInsurance"
              placeholder="homeowner insurance"
              width={310}
              value={property.homeownerInsurance}
              onChange={(value) => updateProperty("homeownerInsurance", value)}
            />
            <NumberInput
              label="hoa"
              fieldname="hoa"
              placeholder="hoa"
              width={160}
              value={property.hoa}
              onChange={(value) => updateProperty("hoa", value)}
            />
            <NumberInput
              label="solar"
              fieldname="solar"
              placeholder="solar"
              width={160}
              value={property.solar}
              onChange={(value) => updateProperty("solar", value)}
            />
          </div>
          <div className="ReoFrame3 self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <NumberInput
              label="rental income"
              fieldname="rentalIncome"
              placeholder="rental income"
              width={310}
              value={property.rentalIncome}
              onChange={(value) => updateProperty("rentalIncome", value)}
            />
            <div className="HousingExpense w-[342px] h-[68px] p-6 bg-gray-800 bg-opacity-80 rounded-lg justify-center items-center gap-3 flex">
              <div className="Title grow shrink basis-0 text-center text-white text-sm font-semibold font-['Inter'] leading-relaxed">
                Housing Expense
              </div>
              <div className="Figure grow shrink basis-0 text-center text-white text-[28px] font-bold font-['Inter']">
                $
                {(
                  property.mortgagePI +
                  property.propertyTax +
                  property.homeownerInsurance +
                  property.hoa +
                  property.solar
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default REOForm;
