// IncomeForm.js
import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import TextInput from "../TextInput";
import NumberInput from "../NumberInput";
import { Form, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useFinancial } from "../FinancialProvider";
import { useSubmitForm } from "../SubmitFormContext"; // 确保从正确的路径导入

// 定义验证模式
const validationSchema = Yup.object({
  borrowerName: Yup.string().required("Borrower name is required"),
  baseIncome: Yup.number()
    .required("Base income is required")
    .positive("Base income must be positive"),
});

const IncomeForm = forwardRef((props, ref) => {
  const { setCoBorrowerName, setBorrowerName, setBaseIncome, setBonusIncome } =
    useFinancial();

  const { registerForm, unregisterForm, setValidationFailed } = useSubmitForm(); // 使用新的hook

  const formik = useFormik({
    initialValues: {
      borrowerName: "",
      coBorrowerName: "",
      baseIncome: 0,
      bonusIncome: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values)); // 打印表单数据的JSON字符串
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      console.log("Validating IncomeForm...");
      const errors = await formik.validateForm();
      formik.setTouched(
        Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      );

      if (Object.keys(errors).length > 0) {
        console.log("IncomeForm validation failed", errors);
        throw new Error("Form validation failed");
      } else {
        console.log("IncomeForm validation succeeded");
        return formik.submitForm();
      }
    },
  }));

  useEffect(() => {
    // 注册表单的提交函数
    registerForm("IncomeForm", formik.submitForm);

    return () => {
      // 组件卸载时取消注册
      unregisterForm("IncomeForm");
    };
  }, [registerForm, unregisterForm, formik.submitForm]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <div className="Incomeforms w-full  p-6  bg-white rounded-xl border border-solid border-zinc-200 flex-col justify-start items-start gap-6 flex">
          <div className="Title text-slate-700 text-lg font-medium font-['Inter']">
            Employment Monthly Income
          </div>
          <div className="IncomeFrame self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <TextInput
              required
              label="Borrower Name"
              fieldname="borrowerName"
              placeholder="Borrower Name"
              defaultValue=""
              width={325}
              value={formik.values.borrowerName}
              onChange={(e) => {
                formik.setFieldValue("borrowerName", e.target.value);
                setBorrowerName(e.target.value); // 直接更新 FinancialProvider 的状态
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.borrowerName &&
                Boolean(formik.errors.borrowerName)
              }
              helperText={
                formik.touched.borrowerName && formik.errors.borrowerName
              }
            />
            <TextInput
              required
              label="CoBorrower Name"
              fieldname="coborrowerName"
              placeholder="Coborrower Name"
              defaultValue=""
              width={325}
              value={formik.values.coBorrowerName}
              onChange={(e) => {
                formik.setFieldValue("coBorrowerName", e.target.value);
                setCoBorrowerName(e.target.value); // 直接更新 FinancialProvider 的状态
              }}
            />
          </div>
          <div className="IncomeFrame self-stretch h-[69px] justify-start items-start gap-[25px] inline-flex ">
            <NumberInput
              required
              label="Base Income"
              fieldname="baseIncome"
              placeholder="Base Income"
              width={325}
              value={formik.values.baseIncome}
              onChange={(newValue) => {
                formik.setFieldValue("baseIncome", newValue);
                setBaseIncome(newValue); // 直接更新 FinancialProvider 的状态
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.baseIncome && Boolean(formik.errors.baseIncome)
              }
              helperText={formik.touched.baseIncome && formik.errors.baseIncome}
            />
            <NumberInput
              required
              label="Bonus Income"
              fieldname="bonusIncome"
              placeholder="Bonus Income"
              width={325}
              value={formik.values.bonusIncome}
              onChange={(newValue) => {
                formik.setFieldValue("bonusIncome", newValue);
                setBonusIncome(newValue); // 直接更新 FinancialProvider 的状态
              }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => formik.submitForm()}
          >
            Test Submit
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
});

export default IncomeForm;
