import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const NumberInput = ({
  label,
  fieldname,
  placeholder,
  width,
  value,
  onChange,
  onBlur,
  error = false,
  helperText = "",
}) => {
  const [displayValue, setDisplayValue] = useState("");

  // 当外部值改变时，更新显示的值（带逗号）
  useEffect(() => {
    setDisplayValue(value !== 0 ? value.toLocaleString() : "");
  }, [value]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, ""); // 移除格式化字符以解析数字
    // 更新外部状态和显示值，允许空字符串表示用户可以清除输入
    if (inputValue === "" || !isNaN(inputValue)) {
      onChange(inputValue === "" ? "" : Number(inputValue));
      setDisplayValue(e.target.value);
    }
  };

  const handleBlur = (e) => {
    onBlur && onBlur(e); // 如果有传入onBlur处理器，则调用
  };

  return (
    <TextField
      label={label}
      name={fieldname}
      value={displayValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      variant="outlined"
      style={{
        width: width ? `${width}px` : "auto",
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-input": {
          height: "16px",
        },
      }}
      error={error}
      helperText={helperText}
    />
  );
};

export default NumberInput;
