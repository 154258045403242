import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // for test we change isloggedin to true
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);

  const login = (token, userInfo) => {
    setIsLoggedIn(true);
    setAccessToken(token);
    setUser(userInfo);
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    sessionStorage.setItem("accessToken", token);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setAccessToken(null);
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("accessToken");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, accessToken, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
