import React from "react";

function HeroText() {
  return (
    <div className="Herotext relative pl-50 pt-10 flex-col justify-start items-center gap-6 inline-flex">
      <div className="Title flex-col justify-start items-center gap-[3.50px] flex">
        <div className="GenericCalculator text-center text-white text-[88px] font-bold font-['Inter'] leading-[88px]">
          Generic Calculator
        </div>
      </div>
      <div className="BoostingTheLoanOfficer w-[491px] text-center text-violet-300 text-lg font-normal font-['Inter'] leading-loose">
        Boosting the Loan Officer
      </div>
    </div>
  );
}

export default HeroText;
//left-[497.50px] top-[132px] absolute
