import React, { createContext, useContext, useState, useEffect } from "react";

const FinancialContext = createContext();

export const useFinancial = () => {
  return useContext(FinancialContext);
};

export const FinancialProvider = ({ children }) => {
  const [borrowerName, setBorrowerName] = useState("");
  const [coBorrowerName, setCoBorrowerName] = useState("");
  const [baseIncome, setBaseIncome] = useState(0); // 初始状态设为数字 0
  const [bonusIncome, setBonusIncome] = useState(0); // 初始状态设为数字 0
  const [totalDebt, setTotalDebt] = useState(0); // 初始状态设为数字 0
  //Subject property 属性
  const [SPTotalHousingExpense, setSPTotalHousingExpense] = useState(0); // 初始状态设为数字 0
  const [SPMortgagePI, setSPMortgagePI] = useState(0); // 初始状态设为数字 0
  const [SPPropertyTax, setSPPropertyTax] = useState(0); // 初始状态设为数字 0
  const [SPHomeownerInsurance, setSPHomeownerInsurance] = useState(0); // 初始状态设为数字 0
  const [SPHoa, setSPHoa] = useState(0); // 初始状态设为数字 0
  const [SPSolar, setSPSolar] = useState(0); // 初始状态设为数字 0
  //状态变量
  const [computedTotalIncome, setComputedTotalIncome] = useState(0);
  const [computedTotalDebt, setComputedTotalDebt] = useState(0);
  const [computedTotalHousingExpense, setComputedTotalHousingExpense] =
    useState(0);
  //计算变量
  const [totalIncome, setTotalIncome] = useState(0);
  const [frontDTI, setFrontDTI] = useState(0);
  const [backDTI, setBackDTI] = useState(0);

  //REO 属性
  const [reoProperties, setReoProperties] = useState([
    {
      mortgagePI: 0,
      propertyTax: 0,
      homeownerInsurance: 0,
      hoa: 0,
      solar: 0,
      rentalIncome: 0,
      housingExpense: 0,
    },
  ]);

  //Debt 属性
  const [debts, setDebts] = useState([{ debtType: "", amount: 0 }]);
  const addDebt = (newDebt) => {
    setDebts([...debts, newDebt]);
  };
  const removeDebt = (index) => {
    const newDebts = debts.filter((_, i) => i !== index);
    setDebts(newDebts);
  };
  const updateDebt = (index, updatedDebt) => {
    const newDebts = debts.map((debt, i) => (i === index ? updatedDebt : debt));
    setDebts(newDebts);
  };

  useEffect(() => {
    calculateFinancials();
  }, [reoProperties, baseIncome, bonusIncome, debts, SPTotalHousingExpense]);

  const exportFinancialDataAsJson = () => {
    const financialData = {
      borrowerName,
      coBorrowerName,
      baseIncome,
      bonusIncome,
      debts,
      SPTotalHousingExpense,
      SPMortgagePI,
      SPPropertyTax,
      SPHomeownerInsurance,
      SPHoa,
      SPSolar,
      reoProperties,
    };
    const dataStr = JSON.stringify(financialData);
    return dataStr;
  };

  const addREOProperty = () => {
    setReoProperties([
      ...reoProperties,
      {
        mortgagePI: 0,
        propertyTax: 0,
        homeownerInsurance: 0,
        hoa: 0,
        solar: 0,
        rentalIncome: 0,
        housingExpense: 0,
      },
    ]);
  };

  const removeREOProperty = (index) => {
    setReoProperties(reoProperties.filter((_, i) => i !== index));
  };

  const calculateFinancials = () => {
    let newTotalDebt = debts.reduce(
      (acc, debt) => acc + (Number(debt.amount) || 0),
      0
    ); // 计算债务总和;

    let newTotalIncome = baseIncome + bonusIncome; // 初始总收入

    let newTotalHousingExpense = SPTotalHousingExpense; // 新增: 所有 REO 的住房开销总和

    reoProperties.forEach((property) => {
      const housingExpense =
        Number(property.mortgagePI) +
        Number(property.propertyTax) +
        Number(property.homeownerInsurance) +
        Number(property.hoa) +
        Number(property.solar);

      newTotalHousingExpense += housingExpense; // 新增: 所有 REO 的住房开销总和
      // 如果房屋开销大于租金收入，则将差额加到债务中
      if (housingExpense > property.rentalIncome) {
        newTotalDebt += housingExpense - property.rentalIncome; // 如果房屋支出大于租金收入，差额加到债务
      } else {
        newTotalIncome += property.rentalIncome - housingExpense; // 否则，租金收入减房屋支出加到总收入
      }
    });

    // 更新总债务和总收入
    setComputedTotalDebt(newTotalDebt);
    setComputedTotalIncome(newTotalIncome);
    setComputedTotalHousingExpense(newTotalHousingExpense);
    // 新增: 设置总住房开销
    const newFrontDTI = computedTotalIncome
      ? (SPTotalHousingExpense / computedTotalIncome) * 100
      : 0;
    const newBackDTI = computedTotalIncome
      ? (computedTotalHousingExpense / computedTotalIncome) * 100
      : 0;
    setFrontDTI(newFrontDTI);
    setBackDTI(newBackDTI);
  };

  return (
    <FinancialContext.Provider
      value={{
        borrowerName,
        setBorrowerName,
        coBorrowerName,
        setCoBorrowerName,

        baseIncome,
        setBaseIncome,
        bonusIncome,
        setBonusIncome,

        debts,
        addDebt,
        removeDebt,
        updateDebt,

        totalDebt,
        setTotalDebt,

        SPTotalHousingExpense,
        setSPTotalHousingExpense,
        totalIncome,
        frontDTI,
        backDTI,
        SPMortgagePI,
        setSPMortgagePI,
        SPPropertyTax,
        setSPPropertyTax,
        SPHomeownerInsurance,
        setSPHomeownerInsurance,
        SPHoa,
        setSPHoa,
        SPSolar,
        setSPSolar,
        reoProperties,
        setReoProperties,
        addREOProperty,
        removeREOProperty,
        computedTotalIncome,
        computedTotalDebt,
        computedTotalHousingExpense,
        exportFinancialDataAsJson,
      }}
    >
      {children}
    </FinancialContext.Provider>
  );
};
